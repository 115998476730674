<template>
  <div>
    <!-- <xml version="1.0" encoding="UTF-8"> -->
    <!-- <svg
      :width="width"
      :height="height"
      viewBox="0 0 16 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      @click="toggleActivity"
    > -->
    <!-- Generator: sketchtool 63.1 (101010) - https://sketch.com -->
    <!-- <title>A213E3DA-A27C-4815-95D0-5A9A00C44E7E</title> -->
   <!-- <desc>Created with sketchtool.</desc>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g
          id="Spec"
          transform="translate(-670.000000, -651.000000)"
          :fill="liked?likedColor:noColor"
          fill-rule="nonzero"
        >
          <g id="like" transform="translate(668.000000, 649.208094)">
            <g id="gestures" transform="translate(2.133786, 2.335484)">
              <path
                d="M3.88202765,3.88202765 L3.88202765,13.5870968 L0.970506912,13.5870968 C0.434489151,13.5870968 3.74610737e-14,13.1525484 3.74610737e-14,12.6165899 L3.74610737e-14,12.6165899 L3.74610737e-14,4.85253456 C3.74610737e-14,4.3165168 0.434489151,3.88202765 0.970506912,3.88202765 L0.970506912,3.88202765 L3.88202765,3.88202765 Z M9.73533576,0.000842485369 L9.8225846,0.0144819041 L10.5353006,0.19266115 C10.9783836,0.303074751 11.3366365,0.606832739 11.518133,1.02574234 C11.699629,1.44418125 11.6768832,1.91284874 11.4551069,2.31138255 L11.4551069,2.31138255 L10.4443239,4.13155856 C10.3230103,4.35049134 10.4083091,4.54146526 10.4504844,4.61302122 C10.4926597,4.68505127 10.6182375,4.85233076 10.8689204,4.85233076 L10.8689204,4.85233076 L13.7358862,4.85231882 C14.7239272,4.85231882 15.5281019,5.65649055 15.5281019,6.64453452 C15.5281019,6.78006387 15.5124638,6.91559419 15.4821355,7.04780635 L15.4821355,7.04780635 L14.4462358,11.8017762 C14.1585969,12.8523713 13.1961452,13.5864123 12.1062175,13.5864123 L12.1062175,13.5864123 L7.27879961,13.5868862 C5.94103347,13.5868862 4.85253233,12.498385 4.85253233,11.1606189 L4.85253233,11.1606189 L4.85253233,6.08251911 C4.85253233,5.48259056 5.0733605,4.90635111 5.47473693,4.45948217 L5.47473693,4.45948217 L9.34443914,0.160437409 C9.4652789,0.0263289791 9.64914434,-0.0291151103 9.8225846,0.0144819041 Z"
                id="Combined-Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>  -->
     <!-- <svg
      id="Component_7_2"
      data-name="Component 7 – 2"
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      viewBox="0 0 27 27"
      @click="toggleActivity"
    >
      <path
        id="Path_18"
        data-name="Path 18"
        d="M1,14H3.727V6.2H1ZM16,6.85a1.337,1.337,0,0,0-1.364-1.3h-4.3l.648-2.97L11,2.371a.956.956,0,0,0-.3-.689L9.98,1,5.493,5.283a1.242,1.242,0,0,0-.4.917v6.5A1.337,1.337,0,0,0,6.455,14h6.136a1.357,1.357,0,0,0,1.255-.793L15.9,8.625A1.232,1.232,0,0,0,16,8.15Z"
        transform="translate(5 5)"
       :fill="liked?likedColor:noColor"
      />
      <g
        id="Group_79"
        data-name="Group 79"
        transform="translate(-476 404) rotate(180)"
      >
        <g
          id="Ellipse_4"
          data-name="Ellipse 4"
          transform="translate(-503 377)"
          fill="none"
          stroke="#79797b"
          stroke-width="1"
        >
          <circle cx="13.5" cy="13.5" r="13.5" stroke="none" />
          <circle cx="13.5" cy="13.5" r="13" fill="none" />
        </g>
      </g>
    </svg>  -->
    <svg
      id="Component_8_1"
      :class='"Component_8_1" + content.objectid'
      data-name="Component 8 – 1"
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      viewBox="0 0 27 27"
       @click="toggleActivity"
    >
      <g id="Path_28" data-name="Path 28" :fill="liked?likedColor:noColor">
        <path
          d="M13.5,0A13.5,13.5,0,1,1,0,13.5,13.5,13.5,0,0,1,13.5,0Z"
          stroke="none"
        />
        <path
          d="M 13.5 1 C 10.16113090515137 1 7.022109985351562 2.300230026245117 4.661169052124023 4.661169052124023 C 2.300230026245117 7.022109985351562 1 10.16113090515137 1 13.5 C 1 16.83887100219727 2.300230026245117 19.97789001464844 4.661169052124023 22.33882904052734 C 7.022109985351562 24.69976997375488 10.16113090515137 26 13.5 26 C 16.83887100219727 26 19.97789001464844 24.69976997375488 22.33882904052734 22.33882904052734 C 24.69976997375488 19.97789001464844 26 16.83887100219727 26 13.5 C 26 10.16113090515137 24.69976997375488 7.022109985351562 22.33882904052734 4.661169052124023 C 19.97789001464844 2.300230026245117 16.83887100219727 1 13.5 1 M 13.5 0 C 20.95584106445312 0 27 6.044160842895508 27 13.5 C 27 20.95584106445312 20.95584106445312 27 13.5 27 C 6.044160842895508 27 0 20.95584106445312 0 13.5 C 0 6.044160842895508 6.044160842895508 0 13.5 0 Z"
          stroke="none"
          fill="#79797b"
        />
      </g>
      <g
        id="thumb_up_black_24dp_1_"
        data-name="thumb_up_black_24dp (1)"
        transform="translate(5 5) rotate(360)"
      >
        <path id="Path_17" data-name="Path 17" d="M0,0H17V17H0Z" fill="none" />
        <path
          id="Path_18"
          :class='"Path_18" + content.objectid'
          data-name="Path 18"
          d="M1,14H3.727V6.2H1ZM16,6.85a1.337,1.337,0,0,0-1.364-1.3h-4.3l.648-2.97L11,2.371a.956.956,0,0,0-.3-.689L9.98,1,5.493,5.283a1.242,1.242,0,0,0-.4.917v6.5A1.337,1.337,0,0,0,6.455,14h6.136a1.357,1.357,0,0,0,1.255-.793L15.9,8.625A1.232,1.232,0,0,0,16,8.15Z"
          :fill="liked?thumbSelectedColor:thumbColor"
        />
      </g>
    </svg>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapGetters } from "vuex";
import firebase from "firebase/app";
import "firebase/database";
import FirebaseActions from "@/mixins/FirebaseActions";
import { _projectName } from "@/provider-config.js";
export default {
  props: {
    content: {
      type: Object,
    },
    likeFireCount: {
      type: Number,
    },
    height: {
      type: String,
    },
    width: {
      type: String,
    },
  },
  data() {
    return {
      liked: false,
      likedColor: null,
      // likedColor: "#ffffff", //nammaflix color
      thumbSelectedColor:"#ffffff",
      noColor: "#161616",
      likeBlackColor: "black",
      thumbColor:"#848486",
      currentState: null,
      likeCount: 0,
      contentLikeCount: null,
      dislikeCountDisplay: null,
      applyColor: "clr-red",
      defaultColor: "like",
    };
  },
  watch: {
    contentLikeCount(val) {
      // console.log("contentLikeCount", val);
      this.$emit("likeCount", val);
      // eventBus.$emit("like-count-to-mixin", val);
    },
    likeFireCount(val) {
      console.log("content from parent", val);
    },
    content(val) {
      // console.log("watch content in like", val);
      this.fetchButtonState();
      this.getContentLikeCount();
      this.getContentDislikeCount();
    },
    subscriberid(val) {
      if (val) {
        this.fetchButtonState();
      }
    },
  },
  computed: {
    ...mapGetters(["subscriberid", "profileid"]),
  },
  mounted() {
    document.getElementsByClassName(`Component_8_1${this.content.objectid}`)[0].addEventListener("mouseover", () => {
      document.getElementsByClassName(`Path_18${this.content.objectid}`)[0].setAttribute("fill", "white")
    });

    document.getElementsByClassName(`Component_8_1${this.content.objectid}`)[0].addEventListener("mouseout", () => {
      if (!this.liked)
      document.getElementsByClassName(`Path_18${this.content.objectid}`)[0].setAttribute("fill", "#848486")
    })


    this.getContentLikeCount();
    this.getContentDislikeCount();
    eventBus.$on("updatelikeDislikeStatus", () => {
      console.log("logged In from like no subscriber Button");
    });
  },
  created() {
    this.fetchButtonState();
    this.likedColor =
      _projectName === "VLIVE"
        ? "#FF9600"
        : _projectName === "NET5"
        ? "#FF9600"
        : _projectName === "NAMMAFLIX"
        ? "#ffffff"
        : _projectName === "Noor Play"
        ? "#560C1D"
        : "";
    // eventBus.$on("decrease-like-count", () => {
    //   eventBus.$emit("like-count-to-mixin", this.contentLikeCount);
    //   this.contentLikeCount =
    //     this.contentLikeCount > 0 ? this.contentLikeCount - 1 : 0;
    //   this.$emit("likeCount", this.contentLikeCount);
    // });
  },
  methods: {
    fetchButtonState() {
      // console.log("entered");
      if (!this.content) return;
      // console.log("content", this.content);
      let content = this.content;

      let path = `/subscriber/${this.subscriberid}/${this.profileid}/content/`;

      if ("seriesid" in content && content.seriesid) {
        // let seriesid = content.seriesid;
        path += `${content.objectid}/episodes/${content.objectid}`;
      } else {
        path += `${content.objectid}`;
      }
      // console.log("button state path", path);
      firebase
        .database()
        .ref(path)
        .on("value", (snapshot) => {
          let response = snapshot.val();

          if (response && response.likestatus === "LIKE") {
            this.liked = true;
            this.action = "REMOVE";
            this.currentState = "";
          } else if (response && response.likestatus === "DISLIKE") {
            this.action = "ADD";
            this.liked = false;
            console.log("current state from like", response.likestatus);
            this.currentState = "DISLIKE";
          } else {
            this.liked = false;
            this.action = "ADD";
            this.currentState = "";
          }
          // console.log("action in likebtn", this.action);
        });
    },
    getContentLikeCount() {
      let payload = {
        objectid: this.content.objectid,
        type: "like",
      };
      this.fetchLikeDislikeCount(payload).then((result) => {
        if (result) {
          this.contentLikeCount = result;
        } else {
          this.contentLikeCount = 0;
        }
        // this.$emit("likeCount", this.contentLikeCount);
        // eventBus.$emit("like-count-to-mixin", this.contentLikeCount);
      });
    },
    getContentDislikeCount() {
      let payload = {
        objectid: this.content.objectid,
        type: "dislike",
      };
      this.fetchLikeDislikeCount(payload).then((result) => {
        if (result) {
          this.dislikeCountDisplay = result;
        } else {
          this.dislikeCountDisplay = 0;
        }
        // console.log("dislikeCount", this.dislikeCountDisplay);
        // this.$emit("dislikeCount", this.dislikeCountDisplay);
        // eventBus.$emit("dislike-count-to-mixin", this.dislikeCountDisplay);
      });
    },
    toggleActivity() {
      if (this.subscriberid) {
        // this.$emit("updateLikeCount", this.action);
        if (this.action === "ADD" && this.currentState === "") {
          // console.log("add");
          // this.updateContentCount(this.content, this.contentLikeCount, "LIKE");
          this.updateLikeDislikeToFire(this.content, "LIKE");
          //calculate counts
          this.contentLikeCount = this.contentLikeCount + 1;
          this.$emit("likeCount", this.contentLikeCount);
        } else if (this.action === "ADD" && this.currentState === "DISLIKE") {
          // when the content is DISLIKE change the status and count
          console.log("dislikeCountDisplay", this.dislikeCountDisplay);
          this.updateContentCount(
            this.content,
            this.contentLikeCount,
            "LIKE",
            this.dislikeCountDisplay
          );
          this.updateLikeDislikeToFire(this.content, "LIKE");
          this.contentLikeCount = this.contentLikeCount + 1;
          // this.dislikeCountDisplay =
          //   this.dislikeCountDisplay > 0 ? this.dislikeCountDisplay - 1 : 0;
          this.$emit("likeCount", this.contentLikeCount);
          this.$emit("dislikeCount", this.dislikeCountDisplay);
        } else if (this.action === "REMOVE") {
          // console.log("remove");
          this.updateContentCount(
            this.content,
            this.contentLikeCount,
            "REMOVELIKE"
          );
          this.updateLikeDislikeToFire(this.content, "NONE");

          //calculate counts
          this.contentLikeCount =
            this.contentLikeCount > 0 ? this.contentLikeCount - 1 : 0;
          this.$emit("likeCount", this.contentLikeCount);
        }
      } else {
        let payload = {
          state: true,
          formType: "lookup",
        };
        eventBus.$emit("authPopup", payload);
      }
    },
  },
  mixins: [FirebaseActions],
};
</script>

<style>
@media only screen and (min-width: 800px) {
  #Component_8_1:hover g { 
    fill: #848486;
 }
}


</style>
